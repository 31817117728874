import "./assets/reset.css";
import { createApp, ref } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "./router";
// import VueParticles from "vue-particles";
import Particles from "particles.vue3";
import { marked } from "marked";

// import mobile from "./functions/mobile";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      count: 0,
      modalOpen: false,
      currentModal: "",
      currentProject: {},
      currentImage: {},
      currentImages: [],
      preferences: {
        fontSize: "Regular",
        particles: 0,
        darkMode: false,
        sound: false,
      },
      currentImg: '',
    };
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    openModal(state, data) {
      state.modalOpen = true;
      state.currentModal = data;
    },
    closeModal(state) {
      state.modalOpen = false;
    },
    updateImageUrl(state, data) {
      state.currentImage = data;
    },
    updateImages(state, data) {
      state.currentImages = data;
    },
    updateCurrentProject(state, data) {
      state.currentProject = data;
    },
    toggleDarkMode(state, data) {
      state.preferences.darkMode = data;
    },
    toggleSound(state) {
      state.preferences.sound = !state.preferences.sound;
      const audio = new Audio(`/sound/cj_INTRO.mp3`);
      if (state.preferences.sound === true) {
        audio.play();
      } else {
        audio.pause();
      }
    },
    changeFontSize(state, data) {
      state.preferences.fontSize = data;
    },
    changeParticles(state, data) {
      state.preferences.particles = data;
    },
    imgUrl(state, data) {
      state.currentImg = data;
    }
  },
});

const app = createApp(App);

app.config.globalProperties.breakpoints = {
  sm: 768,
  md: 1024,
  lg: 1200,
};

app.config.globalProperties.winWidth = ref(window.innerWidth);

app.config.globalProperties.winWidth = ref(window.innerWidth);
app.config.globalProperties.screenMobile = ref(
  window.innerWidth < app.config.globalProperties.breakpoints.sm
);

window.addEventListener("resize", function () {
  app.config.globalProperties.winWidth.value = window.innerWidth;
  app.config.globalProperties.screenMobile.value =
    app.config.globalProperties.winWidth.value <
    app.config.globalProperties.breakpoints.sm;
});

const markedMixin = {
  methods: {
    md: function (input) {
      return marked(input);
    },
  },
};

// window.addEventListener(
//   "resize",
//   () => (mobile.windowWidth.value = window.innerWidth)
// );

// app.directive("resize", {
//   mounted(el, binding) {
//     const onResizeCallback = binding.value;
//     window.addEventListener("resize", () => {
//       const width = document.documentElement.clientWidth;
//       const height = document.documentElement.clientHeight;
//       onResizeCallback({ width, height });
//     });
//   },
// });

app.config.globalProperties.$apiURL = process.env.VUE_APP_API_URL;
// app.use(VueParticles);
app.use(Particles);
app.use(store);
app.mixin(markedMixin);
app.use(router);
app.mount("#app");
