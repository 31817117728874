<template>
  <div
    class="circle"
    :style="{
      backgroundColor: color,
      transform: translate,
    }"
  ></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Circle",
  props: ["ichange"],
  computed: {
    ...mapState(["modalOpen", "currentModal", "preferences"]),
    color() {
      if (this.$store.state.preferences.darkMode === true) {
        return `hsl(${100 + this.randomNumber(155)},${50 +
          this.randomNumber(30)}%, ${20 + this.randomNumber(20)}%)`;
      }
      else {
        return `hsl(${100 + this.randomNumber(155)},${20 +
          this.randomNumber(30)}%, ${60 + this.randomNumber(15)}%)`;
      }
    },
    translate() {
      return (
        this.ichange &&
        `translate(${this.randomNumber(80)}vw, ${this.randomNumber(80)}vh)`
      );
    },
  },
  methods: {
    randomNumber: max => Math.floor(Math.random() * max),
  },
};
</script>

<style lang="scss">
@for $i from 1 through 5 {

  .shape-#{$i} {
    transition: 4s transform ease-in-out, 2s background-color ease-in-out;
    transition-delay: $i * 0.2s;
    filter: blur(100px);
    animation: 8s linear $i * 0.1s infinite alternate fadeinphoto;
  }
}

$radius: 70vw;

.circle {
  width: $radius;
  height: $radius;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
}

@keyframes fadeinphoto {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}
</style>
