<template>
  <section
    class="landing-page"
  >
    <div class="landing-sections">
      <h2 class="landing-header">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1101.51 363.65"
        >
          <path
            class="logo-svg"
            d="M207.24,171.57,84.34,0h90.92l37.21,52L249.68,0H343.5l43.11,60.18H218.36l36.69,51.21h86.69l-43.11,60.18H207.24Z"
          />
          <path
            class="logo-svg"
            d="M612.88,171.57,490,0H580.9l37.31,52.08L655.51,0H806.08L763,60.18H624L703.8,171.57Z"
          />
          <path
            class="logo-svg"
            d="M304.45,191.65l122.9,171.57H336.43L213.53,191.65Zm27.86,0,122.9,171.57h90.91L423.22,191.65Z"
          />
          <path
            class="logo-svg"
            d="M809.19,190l122.9,171.57H841.17l-36.29-50.66L767.1,363.65H676.18l43.11-60.18h80.27L718.27,190Z"
          />
          <path
            class="logo-svg"
            d="M185.57,191.65l122.9,171.57H217.55l-37.86-52.86-37.86,52.86H0L43.11,303H174.45L94.65,191.65Z"
          />
          <path
            class="logo-svg"
            d="M450.91,111.39,371.12,0H462L584.93,171.57H494l-38.33-53.51-38.33,53.51H326.44l43.11-60.18Z"
          />
          <polygon
            class="logo-svg"
            points="698.21 299.2 741.31 239.02 650.4 239.02 613.08 291.12 541.82 191.65 450.91 191.65 573.81 363.22 664.72 363.22 618.86 299.2 698.21 299.2"
          />
          <path
            class="logo-svg"
            d="M1058.4,250.18H971.13l79.79,111.39H960L837.1,190H928l36.19,50.53,36.2-50.53h101.1Z"
          />
          <path
            class="logo-svg"
            d="M710.86,171.72,833.76.15h90.91L801.77,171.72Zm118.83,0h90.92l43.11-60.18H872.8Z"
          />
        </svg>
      </h2>
      <div @click="this.$store.commit('closeModal'); playSound()" class="button-enter">ENTER</div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      showLandingPage: "f",
    };
  },
  mounted() {
    // if (localStorage.showLandingPage) {
    //   this.showLandingPage = localStorage.showLandingPage;
    // }
  },
  computed: {
    ...mapState(["modalOpen", "currentModal"]),
    // showLandingPage() {
    //   return localStorage.getItem("showLandingPage");
    // },
  },
  watch: {
    showLandingPage(newStatus) {
      localStorage.showLandingPage = newStatus;
    },
  },
  methods: {
    onClickCloseButton() {
      // this.showLandingPage = "f";
      // localStorage.setItem("showLandingPage", "f");
      // console.log(this.showLandingPage); /* DEBUG */
    },
    playSound() {
      const audio = new Audio(`/sound/cj_INTRO.mp3`);
      audio.play();
    }
  },
};
</script>
<style lang="scss">
.landing-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  z-index: 10000;

  .landing-sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
  }
}

.landing-header {
  width: 40rem;
  margin-bottom: calc(var(--flow-space) * 2);
  max-width: 100vw;
  padding: 1rem;
}

.button-enter {
  width: 8rem;
  margin: 0;
  padding: 0.3rem 0.1rem 0.2rem 0.1rem;
  font-size: 1.5rem;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  line-height: 140%;
  color: white;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0);
  transition: border-color 0.15s ease-out, background-color 0.15s ease-out 0.1s;
  cursor: url('~@/assets/cursor-selected.svg'), pointer;
  background-color: var(--hover-white);

  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
  }
}
</style>
