<template>
  <Transition>
  <LandingPage  key=1 v-if="modalOpen && (currentModal == 'landingPage')" />
</Transition>
<Transition>
  <AboutModal key=2  v-if="modalOpen && (currentModal == 'about')" />
</Transition>
<Transition>
  <PreferencesModal key=3 v-if="modalOpen && (currentModal == 'preferences')" />
</Transition>
  <div
    class="viewport"
    :class="{
      bigger: preferences.fontSize === 'Bigger',
      regular: preferences.fontSize === 'Regular',
      smaller: preferences.fontSize === 'Smaller',
    }"
  >
    <nav
      class="nav--secondary"
      v-if="!(modalOpen && (currentModal == 'landingPage' || currentModal == 'imageViewer'))"
    >
      <div class="button--about"           
          :class="{ 'link-about': true, active: modalOpen && currentModal == 'about' }"
          @click.stop="toggleModal(e, 'about')">
        <img
          src="~@/assets/SVG/about.svg"
          alt=""
        />
      </div>
      <div
        class="button--preferences"
        v-if="!screenMobile.value"
        @click.stop="toggleModal(e, 'preferences')"
        :class="{ active: modalOpen && currentModal == 'preferences' }"
      >
        <img
          src="~@/assets/SVG/settings.svg"
          alt=""
        />
      </div>
    </nav>
    <NavModal
      :projects="projects"
      v-if="screenMobile.value && modalOpen && currentModal == 'modal--nav'"
    />
    <Transition>
      <ImageViewer v-if="modalOpen && currentModal == 'imageViewer'" />
    </Transition>

    <main
      class="section--main"
      :class="{
        'blurred-out': modalOpen,
        mobile: screenMobile.value,
      }"
    >
      <div class="section--main__dividers">
        <aside
          class="divider-left"
          v-if="!screenMobile.value"
        >
          <ProjectMenu :projects="projects" />
        </aside>
        <section class="divider-right">
          <header class="mobileHeader"             v-if="screenMobile.value">
          <h2
          >
            <div class="leftLine"></div>
            <svg
              @click.stop="toggleModal(e, 'modal--nav')"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 995.2 342.09"
            >
              <polygon
                class="logo-svg"
                points="287.91 341.75 405.89 341.75 406.84 341.75 492.7 221.88 340.03 221.88 266.96 119.87 582.08 119.87 496.21 0 329.33 0 255.21 103.47 181.09 0 0 0 244.8 341.75 287.91 341.75"
              />
              <polygon
                class="logo-svg"
                points="569.31 0.34 728.24 222.22 560.77 222.22 474.91 342.09 663.27 342.09 738.69 236.8 814.11 342.09 995.2 342.09 750.4 0.34 569.31 0.34"
              />
            </svg>
          </h2>
          <aside class="socialMedia">
          <p><a
              href="mailto:contact@carljohan.biz"
              target="_blank"
            >email</a>, <a
              href="https://www.instagram.com/by.charlatan"
              target="_blank"
            >instagram</a></p>
        </aside>
      </header>
          <router-view v-slot="{ Component }">
            <transition
              name="projectswitch"
              mode="out-in"
            >
              <component
                :is="Component"
                :key="$route.params.slug"
                :currentProject="currentProject"
              />
            </transition>
          </router-view>
        </section>
      </div>
    </main>
  </div>
  <BackgroundPane />
</template>

<script>
// TODO: Get index of current image. Get other images. Arrow key switching.
import BackgroundPane from "./components/BackgroundPane.vue";
import PreferencesModal from "./views/PreferencesModal.vue";
import AboutModal from "./views/AboutModal.vue";
import ProjectMenu from "./components/Menu/ProjectMenu.vue";
import NavModal from "./components/NavModal.vue";
import ImageViewer from "./components/ImageViewer.vue";
import LandingPage from "./views/LandingPage.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "App",
  data() {
    return {
      projects: [],
    };
  },
  components: {
    BackgroundPane,
    PreferencesModal,
    NavModal,
    ProjectMenu,
    ImageViewer,
    LandingPage,
    AboutModal,
  },
  created() {
    this.$store.commit("openModal", "landingPage");

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", e => this.handleDarkMode(e))

    // DEBUG: always set it to true to load on every page visit
    // TODO: should it be localstorage or session storage?
    // set to "t" to load, "f" to not load
    // localStorage.setItem("showLandingPage", "f");

    // if (localStorage.getItem("showLandingPage") == null) {
    //   console.log(localStorage.getItem("showLandingPage")); /* DEBUG */
    //   localStorage.setItem("showLandingPage", "t");
    // }

    // if (localStorage.getItem("showLandingPage") == "t") {
    //   this.$store.commit("openModal", "landingPage");
    // }

    axios
      .get(this.$apiURL + "/api/projects?populate=*")
      .then(response => {
        this.projects = response.data.data;

        return (response.data.data);

      }).then((data) => {

        let currentProject;
        // Show the first project on the home path
        if (this.$route.path === "/") {
          currentProject = data[0];
        } else {
          let filteredArray = data.filter(
            p => p?.attributes?.slug == this.$route.params.slug
          );

          // Extracts the project from the array
          [currentProject = 0] = filteredArray;
        }

        // Update the current project in the vue store
        this.$store.commit("updateCurrentProject", currentProject);
      })
      .catch(err => console.error(err));

    axios
      .get(this.$apiURL + "/api/selected-image?populate=*")
      .then(response => {

        let url = response.data.data.attributes.img.data.attributes.url; /* DEBUG */

        this.$store.commit("imgUrl", url);

      }).then(() => {

      })
      .catch(err => console.error(err));
  },
  methods: {
    toggleModal(e, modalName) {
      if (this.$store.state.modalOpen == false) {
        this.$store.commit("openModal", modalName);
        return;
      }
      if (this.$store.state.modalOpen == true) {
        this.$store.commit("closeModal");
        return;
      }
    },
    handleDarkMode(e) {
      const colorScheme = e.matches ? "dark" : "light";

      if (colorScheme === "dark") {
        this.$store.commit("toggleDarkMode", 1);
        console.log('Dark Mode'); /* DEBUG */
        document.body.classList.add("dark")
      } else {
        this.$store.commit("toggleDarkMode", 0);
        console.log('Light Mode'); /* DEBUG */
        document.body.classList.remove("dark")
      }
    }
  },
  computed: {
    ...mapState(["preferences"]),
    currentProject: function () {
      let currentProject;
      // Show the first project on the home path
      if (this.$route.path === "/") {
        currentProject = this.projects[0];
      } else {
        let filteredArray = this.projects.filter(
          p => p.attributes.slug == this.$route.params.slug
        );

        // Extracts the project from the array
        [currentProject = 0] = filteredArray;
      }
      // Update the current project in the vue store
      this.$store.commit("updateCurrentProject", currentProject);
      return currentProject;
    },
    showLandingPage() {
      return localStorage.getItem("showLandingPage");
    },
    modalOpen() {
      return this.$store.state.modalOpen;
    },
    currentModal() {
      return this.$store.state.currentModal;
    },
  },

  mounted() { },
  beforeUnmount() { },
};
</script>

<style lang="scss">
/*====== FONTS ======*/
@font-face {
  font-family: "Hershey-Noailles-Futura-Simplex-Light";
  src: url("~@/assets/fonts/Hershey-Noailles-Futura-Simplex-Light.woff2") format("woff2"),
    url("~@/assets/fonts/Hershey-Noailles-Futura-Simplex-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

::selection {
  background-color: hsla(209, 100%, 90%, 0.2);
}

:root {
  font-size: 18px;
  --flow-space: 1rem;
  --light-grey: rgb(179, 179, 179);
  --dark-blue: #262142;
  --base-font-unit: 1.2rem;
  --transparent-white: rgba(255, 255, 255, 0.3);
  --hover-white: rgba(255, 255, 255, 0.2);
  // --primary-font: "EuroStyle";
  // --primary-font: "Hershey-Noailles-HelpMe";
  // --primary-font: "PCMyungjo";
  // --primary-font: "SimSun";
  --primary-font: "Hershey-Noailles-Futura-Simplex-Light";
  --accent: rgba(202, 241, 255, 0.1);
  // --primary-font: "Lato";
  // --primary-font: "imagineYOKOHAMA";
  // --primary-font: "OCR-A";
  // --primary-font: "AcerSupplement";
  // --primary-font: "Skywerd";
  // delete --primary-font: "Begokv15";
  // --secondary-font: "EuroStyle";
  // --secondary-font: "Hershey-Noailles-HelpMe";
  // --secondary-font: "SimSun";
  // --secondary-font: "PCMyungjo";
  // --secondary-font: "OCR-A";
  // --secondary-font: "imagineYOKOHAMA";
  // --secondary-font: "AcerSupplement";
  // --secondary-font: "Skywerd";
  --secondary-font: "Lato";
  --secondary-font: "Hershey-Noailles-Futura-Simplex-Light";
  cursor: url("~@/assets/cursor-default-2.svg") 1 1, auto;
}

/*====== VUE TRANSITIONS ======*/

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.projectswitch-enter-active,
.projectswitch-leave-active {
  transition: opacity 0.6s ease-in-out 0.1s, transform 0.7s ease-in-out;
}

.projectswitch-enter-from,
.projectswitch-leave-to

/* .projectswitch-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(-50px);
}

.viewport {
  height: 100%;
  position: relative;

  &.bigger {
    --base-font-unit: 1.6rem;
  }

  &.regular {
    --base-font-unit: 1.2rem;
  }

  &.smaller {
    --base-font-unit: 1rem;
  }
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: var(--light-grey);
  overscroll-behavior-y: none;

  transition: 0.2s background-color ease-in-out;

  &.dark {
    background-color: var(--dark-blue);
  }
}

img {
  max-width: 100%;
  max-height: 100%;
  cursor: url("~@/assets/cursor-selected.svg"), pointer;
}

#app {
  // font-family: "Hershey-Noailles-HelpMe", serif;
  font-family: var(--primary-font), serif;
  // font-family: "Hershey-Noailles-HelpMe", serif;
  // font-family: "Hershey-Noailles-HelpMe", serif;
  // font-family: "Hershey-Noailles-HelpMe", serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: url("~@/assets/cursor-selected.svg"), pointer;

  &:visited,
  &:hover {
    text-decoration: none;
  }
}

label {
  cursor: url("~@/assets/cursor-selected.svg"), pointer;
}

u {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 0.5px;
}

.mobileHeader h2 {
  height: 4rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  .leftLine {
    width: 10px;
    height: 74px;
    background-color: var(--transparent-white);
    position: absolute;
    left: 0;
  }

  svg {
    height: 100%;
  }
}

strong,
em {
  background-color: var(--accent);
}

p,
a,
span,
li {
  font-size: var(--base-font-unit);
}

p,
a,
span,
h1,
h2,
h3,
h4 {
  transition: 0.4s font-size ease;
  // letter-spacing: 0.05em;
  // font-weight: 200;
}

h1,
h4 {
  color: white;
  margin: 0;
  font-family: var(--primary-font);
}

h1,
h2,
h3,
h5 {
  color: white;
  font-family: var(--secondary-font);
}

h1,
h2 {
  // font-weight: 300;
}

h1 {
  font-size: calc(var(--base-font-unit) * 2.25);
}

h2 {
  font-size: calc(var(--base-font-unit) * 2.25);
}

h3 {
  font-size: calc(var(--base-font-unit) * 1.5);
}

h4 {
  font-size: calc(var(--base-font-unit) * 1);
}

p,
a {
  font-size: var(--base-font-unit);
  line-height: 1.2;
}

.section--main {
  padding-right: Max(calc(var(--flow-space) * 5), 10vw);
  height: calc(100%);

  transition: filter 1.2s ease, opacity 1.2s cubic-bezier(0.44, 0, 0.11, 1);

  &.blurred-out {
    filter: blur(4px);
    opacity: 0.3;
    pointer-events: none;
    overflow: hidden;

    img {
      filter: grayscale(50%);
      opacity: 0.5;
    }

    // pointer-events: none;
  }

  &__dividers {
    display: grid;
    grid-template-columns: 20rem 1fr;
    grid-template-rows: 100%;
    height: 100%;
    column-gap: 8vw;

    .divider-left {
      padding: var(--flow-space);
      padding-left: calc(var(--flow-space) * 2);
      padding-bottom: calc(var(--flow-space) * 2);
      padding-top: calc(var(--flow-space) * 1.6);
      position: relative;
      // transform: perspective(500px) rotateY(6deg) scale(0.97);
    }

    .divider-right {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      .mobile & {
        -webkit-mask-image: none;
      }

      overflow-y: scroll;
      padding-top: var(--flow-space);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .mobile & {
      grid-template-columns: 1fr 0;
      column-gap: 0rem;
    }
  }

  &.mobile {
    padding: calc(var(--flow-space) * 1.5);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nav--secondary {
  z-index: 1000;

  position: absolute;
  right: 2rem;
  bottom: 2rem;
  display: grid;
  grid-auto-rows: 3rem;
  grid-template-columns: 3rem;
  grid-gap: 2rem;
  justify-items: center;
}

.button--about,
.button--preferences {
  opacity: 0.5;
  transition: 0.2s opacity ease, 0.2s background-color ease;

  &:hover {
    opacity: 1;
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.247);
  }
}

.button--about {
  cursor: url("~@/assets/cursor-selected.svg"), pointer;

  padding: 0.3rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button--preferences {
  padding: 0.1rem;
  width: 100%;
  height: 100%;
  cursor: url("~@/assets/cursor-selected.svg"), pointer;

  img {
    pointer-events: none;
    touch-action: none;
  }
}

.logo-svg {
  stroke: #fff;
  stroke-width: 1.5px;
  fill: rgba(255, 255, 255, 0);
  vector-effect: non-scaling-stroke;

  opacity: 0.5;

  transition: 0.2s opacity ease-in-out;

  &:hover {
    fill: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
}

// TODO: Style the scrollbars
/* Scrollbar Styling Start */
/* width */
// ::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f148;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
//   border-radius: 5px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
/* Scrollbar Styling Ends */

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
