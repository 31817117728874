<template>
  <div class="corners">
    <div class="corner"></div>
    <div class="corner"></div>
    <div class="corner"></div>
    <div class="corner"></div>
  </div>
</template>
<style lang="scss">
.corners {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  // opacity: 0.5;

  transition: 0.2s opacity ease-in-out;

  &:hover {
    opacity: 1;
  }

  .corner {
    $size: 20px;
    $border-style: 1px solid var(--transparent-white);
    $gap: -15px;
    width: $size;
    height: $size;
    position: absolute;

    &:nth-child(1) {
      border-top: $border-style;
      border-left: $border-style;
      top: -1 * $size - $gap;
      left: -1 * $size - $gap;
    }

    &:nth-child(2) {
      border-top: $border-style;
      border-right: $border-style;
      top: -1 * $size - $gap;
      right: -1 * $size - $gap;
    }
    &:nth-child(3) {
      border-bottom: $border-style;
      border-right: $border-style;
      bottom: -1 * $size - $gap;
      right: -1 * $size - $gap;
    }
    &:nth-child(4) {
      border-bottom: $border-style;
      border-left: $border-style;

      bottom: -1 * $size - $gap;
      left: -1 * $size - $gap;
    }
  }
}
</style>
