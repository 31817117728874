<template>
  <div
    v-if="currentProject"
    class="project-container"
  >
    <div class="project-container__top">
      <h2
        data-modal="modal--nav"
        @click.stop="setCurrentModal"
      >
        <span class="spanIdentifier">{{ currentProject.attributes.year }}</span>
        {{ currentProject.attributes.title.toLowerCase() }}
      </h2>
      <ToggleButton
        enableText="text"
        disableText="images"
        @change="triggerToggleEvent"
      />
    </div>
    <div class="project-container__main">
      <transition
        name="fade_p"
        mode="out-in"
      >
        <section v-if="toggleActive === 'text'">
          <article
            v-if="currentProject.attributes?.description !== ''"
            v-html="textToMarkdown(currentProject.attributes?.description)"
          ></article>
          <p v-if="currentProject.attributes?.description === ''">
            No description yet.
          </p>
        </section>
        <section v-else>
          <ImageGallery
            v-if="currentProjectImages"
            :currentProjectImages="currentProjectImages"
            :initialImage="currentProjectImages[0]"
            :toggleActive="toggleActive"
          />
          <p v-if="!currentProjectImages">No images yet.</p>
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import ImageGallery from "../components/ImageGallery.vue";
import ToggleButton from "../components/ToggleButton.vue";

export default {
  props: ["currentProject"],
  name: "ProjectContainer",
  data() {
    return {
      toggleActive: "images",
      markdown: "# hello world",
    };
  },
  methods: {
    triggerToggleEvent(value) {
      this.toggleActive = value;
    },
    textToMarkdown(input) {
      // return this.md(input).split(' ').map(x => `<span>${x}</span>`).join(' ');
      return this.md(input);
    },
  },
  components: {
    ToggleButton,
    ImageGallery,
  },
  computed: {
    currentProjectImages() {
      return this.currentProject.attributes.images.data;
    },
    screenIsMobile() {
      return this.winWidth.value < this.breakpoints.sm;
    },
  },
};
</script>

<style lang="scss" scoped>
.project-container {
  margin-bottom: 2rem;
  color: white;
}

.project-container__top {
  height: min-content;
  margin-top: 9.3rem;

  .mobile & {
    margin-top: 2.5rem;
  }

  h2 {
    margin-bottom: 2rem;
    line-height: 0.75;
  }
}

:deep(article) {
  p {
    margin-bottom: 1rem;
    max-width: 60ch;
  }

  // @for $i from 1 through 100 {
  //   p span:nth-child(#{$i})::selection {
  //     background-color: hsla(random(255), 80%, 86%, 0.17);
  //   }
  // }

  @for $i from 1 through 20 {
    p:nth-child(#{$i})::selection {
      background-color: hsla(random(255), 80%, 86%, 0.17);
    }
  }

  a {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;
    transition: 0.2s background-color ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.project-container__main {
  margin-top: calc(var(--flow-space) * 2);
  overflow-y: scroll;
  margin-bottom: 7rem;
  height: calc(100%);
  // border: 1px solid green; /* DEBUG */
}

h2 {
  // white-space: nowrap;
  line-height: 0.85;
}

.fade-enter-active,
.fade-leave-active {
  h2 {
    transition: opacity 0.6s ease-in-out 0.1s, transform 0.6s ease-in-out;
  }

  p {
    transition: opacity 0.4s ease-in-out 0.1s, transform 0.4s ease-in-out;
  }
}

.fade-enter-from,
.fade-leave-to {
  h2 {
    opacity: 0;
    transform: translateY(-50px);
  }

  p {
    opacity: 0;
    transform: translateY(40px);
  }
}

.fade_p-enter-active,
.fade_p-leave-active {
  transition: all 0.7s ease-in-out;
}

.fade_p-enter-from,
.fade_p-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  filter: blur(5px);
}
</style>
