<template>
  <section class="image-gallery">
    <div
      v-for="(image, index) in currentProjectImages"
      :key="image.id"
      class="image-wrapper"
      :style="{ '--i': index }"
      @click="openViewer(image)"
    >
      <div class="image-tiltWrapper">
        <img
          class="waiter"
          v-if="!loaded"
          :src="ThumbnailImgURL(image)"
          alt=""
        />
        <img
          @load="loaded = true"
          :src="$apiURL + image.attributes.url"
          alt=""
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    currentProjectImages: {
      type: Array,
    },
    initialImage: {
      required: true,
    },
    toggleActive: {
      type: String,
    },
  },
  data() {
    return {
      currentlySelectedImg: this.initialImage.attributes.hash,
      loaded: false,
      viewerOpen: false,
      tiltOptions: {
        reverse: false, // reverse the tilt direction
        max: 30, // max tilt rotation (degrees)
        startX: 0, // the starting tilt on the X axis, in degrees.
        startY: 0, // the starting tilt on the Y axis, in degrees.
        perspective: 10000, // Transform perspective, the lower the more extreme the tilt gets.
        scale: 1, // 2 = 200%, 1.5 = 150%, etc..
        speed: 300, // Speed of the enter/exit transition
        transition: true, // Set a transition on enter/exit.
        axis: null, // What axis should be disabled. Can be X or Y.
        reset: true, // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
        glare: false, // if it should have a "glare" effect
        "max-glare": 1, // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false, // false = VanillaTilt creates the glare elements for you, otherwise
        // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
        "mouse-event-element": null, // css-selector or link to HTML-element what will be listen mouse events
        // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
        gyroscope: true, // Boolean to enable/disable device orientation detection,
        gyroscopeMinAngleX: -45, // This is the bottom limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the left border of the element;
        gyroscopeMaxAngleX: 45, // This is the top limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the right border of the element;
        gyroscopeMinAngleY: -45, // This is the bottom limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the top border of the element;
        gyroscopeMaxAngleY: 45, // This is the top limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the bottom border of the element;
      },
    };
  },
  created() {
    this.$store.commit("updateImages", this.currentProjectImages);
  },
  methods: {
    currentlySelected: function (value) {
      this.currentlySelectedImg = value;
    },
    ThumbnailImgURL(image) {
      return this.$apiURL + image.attributes.formats.thumbnail?.url;
    },
    openViewer(data) {
      this.viewerOpen = true;
      // TODO: Send whole image down; handle loading in the viewer
      let url = data?.attributes.url;
      let id = data?.id;
      this.$store.commit("updateImageUrl", { url, id });
      this.$store.commit("openModal", "imageViewer");
    },
    onClickChild(value) {
      this.viewerOpen = value;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
// TODO: don't hover when mouse is in margin
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.image-tiltWrapper {
  border: 1px solid var(--transparent-white);
  display: grid;
  position: relative;
  opacity: 0;
  animation: comeIn 0.5s ease;
  animation-delay: calc((var(--i) + 1) * 0.3s);
  animation-fill-mode: forwards;

  &:hover {
    background-color: var(--hover-white);
  }
}

.image-tiltWrapper::before {
  content: "";
  padding-bottom: 100%;
  display: block;
  grid-area: 1 / 1 / 2 / 2;
}

.image-gallery:hover img:not(:hover) {
  opacity: 0.5;
  filter: blur(5px);
}

.image-wrapper {
  position: relative;

  img {
    transition: 0.2s opacity ease-in-out, 0.4s filter ease-in-out;
    grid-area: 1 / 1 / 2 / 2;
    padding: var(--flow-space);
    position: absolute;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 20px);

    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@keyframes comeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
