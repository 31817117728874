<template>
  <transition name="nav">
    <nav
      class="nav-modal"
      @click.stop="openPanel"
    >
      <ProjectMenu :projects="projects" />
    </nav>
  </transition>
</template>

<script>
import ProjectMenu from "./Menu/ProjectMenu.vue";
export default {
  props: ["projects", "currentModal", "modalOpen"],
  components: {
    ProjectMenu,
  },
  methods: {
    openPanel() {
      this.$emit("openPanel");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-enter-active,
.nav-leave-active {
  transition: opacity 0.8s;

  h1 {
    transition: transform 0.8s, opacity 0.8s;
  }
}

.nav-enter-to,
.nav-leave-from {
  opacity: 1;

  h1 {
    transform: translateX(0%);
    opacity: 1;
  }
}

.nav-enter-from,
.nav-leave-to {
  opacity: 0;

  h1 {
    transform: translateX(-1rem);
    opacity: 0;
  }
}

.nav-modal {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 500;
  padding: calc(var(--flow-space) * 1.5);
  padding-top: 1rem;
}

h1 {
  margin-bottom: calc(2 * var(--flow-space));
}
</style>
