<template>
  <nav
    class="navigationPane"
    @click.self="toggleModal(e, 'modal--nav')"
  >
    <header
     class="header-mobile"
     @click.stop="toggleModal(e, 'modal--nav')"
     v-if="screenMobile.value"
>
        <h2>
          <svg
            @click.stop="toggleModal(e, 'modal--nav')"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 995.2 342.09"
          >
            <polygon
              class="logo-svg"
              points="287.91 341.75 405.89 341.75 406.84 341.75 492.7 221.88 340.03 221.88 266.96 119.87 582.08 119.87 496.21 0 329.33 0 255.21 103.47 181.09 0 0 0 244.8 341.75 287.91 341.75"
            />
            <polygon
              class="logo-svg"
              points="569.31 0.34 728.24 222.22 560.77 222.22 474.91 342.09 663.27 342.09 738.69 236.8 814.11 342.09 995.2 342.09 750.4 0.34 569.31 0.34"
            />
          </svg>
        </h2>
        <aside class="socialMedia">
          <p><a
              href="mailto:contact@carljohan.biz"
              target="_blank"
            >email</a>, <a
              href="https://www.instagram.com/by.charlatan"
              target="_blank"
            >instagram</a></p>
        </aside>
      </header>
      <header class="header-desktop" v-if="!screenMobile.value">
        <h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1459.44 127.21"
          >
            <path
              class="logo-svg"
              data-number="1"
              @mouseenter="playSound(1)"
              d="M90,126.77,0,1.08H66.6L93.86,39.14,121.12,1.08h68.73l31.58,44.09H98.18l26.87,37.51h63.51L157,126.77H90Z"
            />
            <path
              class="logo-svg"
              data-number="2"
              @mouseenter="playSound(2)"
              d="M387.18,126.77l-90-125.69h66.6l27.33,38.16L418.41,1.08h110.3L497.13,45.17H395.33l58.45,81.6Z"
            />
            <path
              class="logo-svg"
              data-number="3"
              @mouseenter="playSound(3)"
              d="M268.53,82.68,210.08,1.08h66.6l90,125.69h-66.6L272,87.57,244,126.77h-66.6l31.58-44.09Z"
            />
            <path
              class="logo-svg"
              data-number="4"
              @mouseenter="playSound(4)"
              d="M459,126.88,549,1.19h66.6l-90,125.69Zm87.05,0h66.6l31.58-44.09h-66.6Z"
            />
            <path
              class="logo-svg"
              data-number="5"
              @mouseenter="playSound(5)"
              d="M875.56,1.21l90,125.68H899L809,1.21Zm20.41,0,90,125.68h66.6l-90-125.68Z"
            />
            <path
              class="logo-svg"
              data-number="6"
              @mouseenter="playSound(6)"
              d="M1245.31,0l90,125.68h-66.6l-26.59-37.11-27.68,38.64h-66.6l31.58-44.08h58.8L1178.71,0Z"
            />
            <path
              class="logo-svg" data-number="7"
              @mouseenter="playSound(7)"
              d="M788.48,1.21l90,125.68h-66.6L784.17,88.17l-27.74,38.72H687.16l31.58-44.08h61.59L721.88,1.21Z"
            />
            <path
              class="logo-svg"
              data-number="8"
              @mouseenter="playSound(8)"
              d="M1105.89,80l33.59,46.9h-66.6l-90-125.68h66.6l52.2,72.86L1129,35.91h66.6L1164,80Z"
            />
            <path
              class="logo-svg"
              data-number="9"
              @mouseenter="playSound(9)"
              d="M1427.86,44.08h-63.93l58.45,81.6h-66.6L1265.75,0h66.6l26.52,37,26.51-37h74.06Z"
            />
          </svg>
        </h1>
        <aside class="socialMedia">
          <p><a
              href="mailto:contact@carljohan.biz"
              target="_blank"
            >email</a>, <a
              href="https://www.instagram.com/by.charlatan"
              target="_blank"
            >instagram</a></p>
        </aside>
      </header>
    <section class="menu">
      <Tilt
        v-if="!screenMobile.value"
        :options="tiltOptions"
        :parallax="true"
      >
        <div class="ProjectMenu">
          <div class="border-square"></div>
          <div class="ProjectMenu__items">
            <MenuItem
              v-for="(project, index) in projects"
              :key="project.id"
              :project="project"
              :index="index"
            />
          </div>
          <Corners />
        </div>
      </Tilt>
      <div
        v-if="screenMobile.value"
        class="ProjectMenu"
        :class="{ mobile: screenIsMobile }"
        @click.stop="toggleModal(e, 'modal--nav')"
      >
        <div class="border-square"></div>
        <div class="ProjectMenu__items">
          <MenuItem
            v-for="project in projects"
            :key="project.id"
            :project="project"
            @click.stop="toggleModal(e, 'modal--nav')"
          />
        </div>
        <Corners />
      </div>
    </section>
          <div
        class="popUpFrame"
        v-if="!screenMobile.value"
      >
        <img
          class="popUpImage"
          :src="$apiURL + currentImg"
        />
        <Corners />
      </div>
  </nav>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import Tilt from "vanilla-tilt-vue";
import Corners from "../base/Corners.vue";
import { mapState } from "vuex";

export default {
  components: { MenuItem, Tilt, Corners },
  name: "ProjectMenu",
  props: ["projects"],
  data() {
    return {
      tiltOptions: {
        reverse: false, // reverse the tilt direction
        max: 10, // max tilt rotation (degrees)
        startX: 0, // the starting tilt on the X axis, in degrees.
        startY: 0, // the starting tilt on the Y axis, in degrees.
        perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
        scale: 1, // 2 = 200%, 1.5 = 150%, etc..
        speed: 300, // Speed of the enter/exit transition
        transition: true, // Set a transition on enter/exit.
        axis: null, // What axis should be disabled. Can be X or Y.
        reset: true, // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
        glare: false, // if it should have a "glare" effect
        "max-glare": 1, // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false, // false = VanillaTilt creates the glare elements for you, otherwise
        // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
        "mouse-event-element": null, // css-selector or link to HTML-element what will be listen mouse events
        // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
        gyroscope: true, // Boolean to enable/disable device orientation detection,
        gyroscopeMinAngleX: -45, // This is the bottom limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the left border of the element;
        gyroscopeMaxAngleX: 45, // This is the top limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the right border of the element;
        gyroscopeMinAngleY: -45, // This is the bottom limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the top border of the element;
        gyroscopeMaxAngleY: 45, // This is the top limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the bottom border of the element;
      },
    };
  },
  methods: {
    toggleModal(e, modalName) {
      if (this.$store.state.modalOpen == false) {
        this.$store.commit("openModal", modalName);
        return;
      }
      if (this.$store.state.modalOpen == true) {
        this.$store.commit("closeModal");
        return;
      }
    },
    playSound(number) {
      const audio = new Audio(`/sound/cj_LogoUI_${number}.mp3`);
      audio.play();
    }
  },
  computed: {
    ...mapState(["currentImg"]),
    screenIsMobile() {
      return this.winWidth.value < this.breakpoints.sm;
    },
  },
};
</script>

<style lang="scss">
.header-desktop {
  padding-top: var(--flow-space);
  margin-bottom: calc(var(--flow-space) * 3);

  display: flex;

  .socialMedia {
    white-space: nowrap;
    margin-left: 1rem;
  }
}

.header-mobile {

  h2 {
    margin-top: 1rem;
    height: 4rem;
    display: block;

    svg {
      height: 100%;
    }
  }

  .socialMedia {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.link-about {
  color: white;
}

.logoNav {
  color: white;
  // padding-left: var(--flow-space);

  white-space: nowrap;
}

.socialMedia {
  color: white;

  a {
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5));
    background-size: 0 1px;
    //TODO: make it float lower
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    &:hover {
      background-size: 100% 1px;
    }
  }
}

.navigationPane {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

h1 svg {
  margin-top: 0.5rem;
  height: 3.5rem;
  margin-left: -0.5rem;
  z-index: 500;
  position: relative;
  display: inline-block;
  // opacity: 0;
}


.menu {
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 2;
  flex-grow: 2;
}

.popUpFrame {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 200;
  min-height: 4rem;
  flex-basis: 30%;
  flex-shrink: 2;
  flex-grow: 2;

  transition: 0.8s height ease-in-out, 0.8s width ease-in-out, 0.8s padding ease-in-out, 0.4s background-color ease-in-out, 0.4s flex-basis ease-in-out;

  padding: calc(var(--flow-space) * 0.5);

  display: flex;
  place-content: center;

  margin-top: calc(var(--flow-space)*2);

  &:hover {
    height: 100%;
    width: calc(100vw - calc(var(--flow-space)*10));
    backdrop-filter: blur(3px);
    padding: calc(var(--flow-space) * 1);
    background-color: rgba(255, 255, 255, 0.1);
    flex-basis: 100%;
  }

  .imageShow {
    border: 1px solid white;
    height: 100%;
    max-width: 100%;
  }

  .popUpImage {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

.ProjectMenu {
  height: 100%;
  transform-origin: top center;
  // box-shadow: rgba(100, 100, 111, 0.1) -20px 7px 29px 0px;
  // border: 1px solid rgba(255, 255, 255, 0.3);

  position: relative;

  &.mobile {
    transform: none;
    z-index: 100;

  }
}

.ProjectMenu__items {
  transform: translateZ(20px);
  max-height: 19rem;

  padding: calc(var(--flow-space)*0.5);
  overflow-y: scroll;
}

.menuItem {
  position: relative;
  padding: 1rem;
  color: white;
}
</style>
