<template>
  <router-link
    :class="[
      'menuItem',
      { mobile: screenMobile.value },
      // FIXME: Matcht this to the value that i obtain when the person hits the webpage
      { active: getId == this.$route.params.slug || (this.$route.path == '/' && index === 0) },
    ]"
    :to="getSlug"
  >
    <div
      :data-title="title"
      class="menuItem-contents"
    >
      <h4>{{ title.toLowerCase() }}</h4>
      <h5>{{ year }}</h5>
    </div>
  </router-link>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "MenuItem",
  props: {
    project: Object,
    index: Number,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["currentProject"]),
    title() {
      return this.project.attributes?.title;
    },
    year() {
      return this.project.attributes?.year;
    },
    getSlug() {
      return "/projects/" + this.project.attributes?.slug;
    },
    getId() {
      // Fix the slug being rendered on pageload... and adding the active class reactivity...
      return this.project.attributes?.slug;
    },
  },
};
</script>

<style scoped lang="scss">
h3,
h4 {
  pointer-events: none;
}

@keyframes menu-flip {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }

  33% {
    background-color: rgba(255, 255, 255, 0.1);
  }

  66% {
    background-color: rgba(255, 255, 255, 0);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.menuItem {
  width: 100%;
  display: flex;
  padding: var(--flow-space);
  margin-bottom: 0.5rem;
  position: relative;

  h3,
  h4 {
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5));
    background-size: 0 1px;
    //TODO: make it float lower
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    margin-right: var(--flow-space);
    display: block;
  }

  h3 {
    margin-right: 1rem;
  }

  &:hover {

    h1,
    h2,
    h3,
    h4 {
      background-size: 100% 1px;
    }
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.1);
    transition: 0.4s background-color ease-in-out, 0.4s color ease-in-out;
    animation-name: menu-flip;
    animation-duration: 0.4s;
  }
}

.menuItem-contents {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}
</style>
