<template>
  <div class="background-pane">
    <Circle
      v-for="n in numberOfCircles"
      :key="n"
      :class="[`shape-${n}`]"
      :ichange="ichange"
      :max="255"
    />
  </div>
  <Particles
    v-if="preferences.particles !== 0"
    :class="{ off: preferences.particles == 0 }"
    id="tsparticles"
    :options="options"
    :particlesInit="particlesInit"
  />
</template>

<script>
import { loadFull } from "tsparticles";
import Circle from "./base/Circle.vue";
import { mapState } from "vuex";

export default {
  components: { Circle },
  data() {
    return {
      numberOfCircles: 5,
      intervalId: "",
      ichange: "no",
      options: {
        fpsLimit: 120,
        particles: {
          color: { value: "#ffffff" },
          move: {
            direction: "none",
            enable: true,
            outModes: "out",
            random: true,
            speed: 1.2,
            straight: false,
          },
          number: { density: { enable: true, area: 800 }, value: 70 },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "square",
          },
          size: {
            value: { min: 1, max: 2 },
          },
        },
      },
      particlesInit: async engine => {
        await loadFull(engine);
      },
    };
  },
  methods: {
    interval() {
      this.intervalId = setInterval(() => {
        this.ichange = this.ichange == "no" ? "yes" : "no";
      }, this.numberOfCircles * 3000);
    },
  },
  computed: {
    ...mapState(["preferences"]),
  },
  created() {
    this.interval();
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped lang="scss">
// #tsparticles {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 100;
// }

#tsparticles {
  transition: opacity 0.35s ease-in-out;

  &.off {
    display: none;
  }
}

.background-pane {
  position: fixed;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
