<template>
  <div class="image-viewer">
    <div
      class="overlay-left"
      @click="changeImageClick('ArrowLeft')"
    ></div>
    <div
      class="overlay-right"
      @click="changeImageClick('ArrowRight')"
    ></div>
    <img
      :src="$apiURL + currentUrl"
      :key="currentUrl"
      alt=""
      @touchstart="touchStart"
      :class="{ noCursor: !screenMobile.value }"
    />
    <div
      class="close-button"
      @click="onClickCloseButton"
    >x</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      viewerOpen: true,
      currentId: 1,
      currIndex: "",
    };
  },
  created() {
    this.initialiseId();
  },
  mounted() {
    window.addEventListener("keydown", this.changeImage);
  },
  unmounted() {
    window.removeEventListener("keydown", this.changeImage);
  },
  methods: {
    onClickCloseButton() {
      this.viewerOpen = false;
      this.$store.commit("closeModal");
    },
    initialiseId() {
      // find the ID from the loaded image, and then find its index.
      this.currentId = this.$store.state.currentImage.id;
      this.currIndex = this.currentImages.findIndex(
        image => image.id == this.currentId
      );
    },
    changeImage(e) {
      switch (e.key) {
        case "ArrowLeft":
          if (this.currIndex >= 1) this.currIndex--;
          break;
        case "ArrowRight":
          if (this.currIndex < this.currentImages.length - 1) this.currIndex++;
          break;
      }
    },
    changeImageClick(type) {
      switch (type) {
        case "ArrowLeft":
          if (this.currIndex >= 1) this.currIndex--;
          break;
        case "ArrowRight":
          if (this.currIndex < this.currentImages.length - 1) this.currIndex++;
          break;
      }
    },
    touchStart(touchEvent) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), { once: true });
    },
    touchEnd(touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if (posXStart < posXEnd) {
        if (this.currIndex >= 1) this.currIndex--;
        // this.previous(); // swipe right
      } else if (posXStart > posXEnd) {
        if (this.currIndex < this.currentImages.length - 1) this.currIndex++;
        // this.next(); // swipe left
      }
    },
  },
  computed: {
    ...mapState(["modalOpen", "currentModal", "currentImage", "currentImages"]),
    currentUrl() {
      return this.currentImages[this.currIndex]?.attributes.url;
    },
  },
};
</script>
<style lang="scss">
.close-button {
  z-index: 10000;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: white;
  font-size: 5rem;
  line-height: 2rem;
  cursor: url("~@/assets/cursor-selected.svg"), pointer;
}

.overlay-left {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 50%;
  cursor: url("~@/assets/left.svg"), w-resize;
}

.overlay-right {
  top: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 50%;
  cursor: url("~@/assets/right.svg"), e-resize;
}

.image-viewer {
  padding: calc(var(--flow-space) * 2);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  .noCursor {
    pointer-events: none;
  }

  img {
    z-index: 200;
  }
}
</style>
