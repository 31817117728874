<template>
  <transition>
    <section
      v-if="modalOpen && currentModal == 'about'"
      class="about"
      @click.self="closeModal"
    >
      <article
        class="
    article-about"
        v-html="textToMarkdown(this.aboutContents)"
      >
      </article>
      <article class="credits">
        <p>
          <img
            class="thumbnail"
            src="~@/assets/windmill.gif"
            alt="Photo of a windmill."
          />
          Hosted on carbon-neutral servers powered by wind energy.
        </p>
        <p>Visual Identity and Web Development by dominik.ooo.</p>
      </article>
    </section>
  </transition>

</template>
<style lang="scss" scoped>
.v-leave-active,
.v-enter-active {
  transition: 0.4s opacity ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      viewerOpen: true,
      aboutContents: "",
    };
  },
  created() {
    axios
      .get(this.$apiURL + "/api/about?populate=*")
      .then(response => {
        this.aboutContents = response.data.data.attributes.About;
      })
      .catch(err => console.error(err));
  },
  methods: {
    textToMarkdown(input) {
      return this.md(input);
    },
    toggleModal(e, modalName) {
      if (this.$store.state.modalOpen == false) {
        this.$store.commit("openModal", modalName);
        return;
      }
      if (this.$store.state.modalOpen == true) {
        this.$store.commit("closeModal");
        return;
      }
    },
    closeModal() {
      this.$store.commit("closeModal");
    }
  },
  computed: {
    ...mapState(["modalOpen", "currentModal", "preferences"]),
  }
};
</script>
<style lang="scss">
.about {
  padding: calc(var(--flow-space) * 2);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: scroll;
  color: white;
}

.article-about {
  margin-top: calc(var(--flow-space) * 2);
  max-width: 80ch;

  p,
  h2,
  h3,
  h4,
  h5,
  ul {
    margin-bottom: var(--flow-space);
    max-width: 65ch;
  }

  li {
    list-style: disc;
    margin-left: var(--flow-space);
  }

  h1 {
    margin-bottom: 1rem;
  }
}

.credits {
  margin-top: calc(var(--flow-space) * 2);
  margin-bottom: 6rem;
}

.thumbnail {
  filter: saturate(1);
  display: block;
  height: 80px;
  margin-bottom: var(--flow-space);

  transition: 0.4s filter ease-in-out;

  &:hover {
    filter: blur(5px);
  }
}
</style>
