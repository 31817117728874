import { createRouter, createWebHistory } from "vue-router";
import ProjectContainer from "../views/ProjectContainer.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: ProjectContainer
  },
  {
    path: "/projects/:slug",
    name: "Project",
    component: ProjectContainer
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
