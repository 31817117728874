<template>
  <transition :style="{ '--total': preferences.length }" name="list">
    <article
      v-if="modalOpen && currentModal == 'preferences'"
      @click.self="toggleModal('preferences')"
      class="preferences"
    >
      <header class="preferences-header">
        <h2>preferences</h2>
      </header>
      <div class="preferences-panel">
        <!-- <transition-group name="list" appear> -->

        <div class="panel--section">
          <fieldset>
            <div class="options">
              <div class="options-toggle">
                <input
                  type="radio"
                  name="fontSize"
                  value="Bigger"
                  id="fontSize_button_1"
                  v-model="toggleFont"
                />
                <label for="fontSize_button_1">bigger</label>
                <input
                  type="radio"
                  name="fontSize"
                  value="Regular"
                  id="fontSize_button_2"
                  v-model="toggleFont"
                />
                <label for="fontSize_button_2">regular</label>
                <input
                  type="radio"
                  name="fontSize"
                  value="Smaller"
                  id="fontSize_button_3"
                  v-model="toggleFont"
                />
                <label for="fontSize_button_3">smaller</label>
              </div>
              <div class="options--label">
                <p>font size</p>
              </div>
            </div>
          </fieldset>
          <Corners />
        </div>
        <div class="panel--section">
          <fieldset>
            <div class="options">
              <div class="options-toggle">
                <input
                  type="checkbox"
                  name="darkMode"
                  id="darkMode_button_1"
                  v-model="toggleDarkMode"
                />
                <label
                  v-if="preferences.darkMode === true"
                  for="darkMode_button_1"
                >ON</label>
                <label
                  v-if="preferences.darkMode === false"
                  for="darkMode_button_1"
                >OFF</label>
              </div>
              <div class="options--label">
                <p>dark mode</p>
              </div>
            </div>
          </fieldset>
          <Corners />
        </div>
        <div class="panel--section">
          <div class="location">
            <div>
              <p>SUNRISE</p>
              <p>
                {{ getTimeString(sunsetData.results?.sunrise) }}
              </p>
            </div>
            <div>
              <p>{{ getCoordinates(locationData?.latitude, true) }}</p>
              <p>{{ getCoordinates(locationData?.longitude, true) }}</p>
            </div>
            <!-- <p>{{ [locationData.latitude, locationData.longitude] }}</p> -->
            <div>
              <p>SUNSET</p>
              <p>
                {{ getTimeString(sunsetData.results?.sunset) }}
              </p>
            </div>
            <img class="img-backdrop" src="@/assets/compassShape.svg" alt=""/>
          </div>
          <Corners />
        </div>
        <div class="panel--section">
          <fieldset>
            <div class="options">
              <div class="options-toggle">
                <input
                  type="checkbox"
                  name="sound"
                  id="sound_button_1"
                  v-model="toggleSound"
                />
                <label
                  v-if="preferences.sound === true"
                  for="sound_button_1"
                >ON</label>
                <label
                  v-if="preferences.sound === false"
                  for="sound_button_1"
                >OFF</label>
              </div>
              <div class="options--label">
                <p>sound</p>
              </div>
            </div>
          </fieldset>
          <Corners />
        </div>
        <div class="panel--section">
          <fieldset>
            <div class="options">
              <div class="options-toggle">
                <input
                  type="checkbox"
                  name="particles"
                  id="particles_button_1"
                  v-model="toggleParticles"
                />
                <label for="particles_button_1" v-if="preferences.particles == true">ON</label>
                <label for="particles_button_1" v-if="preferences.particles == false">OFF</label>
              </div>
              <!-- <div class="options-toggle">
                <input
                  type="radio"
                  name="particles"
                  value="3"
                  id="particles_button_1"
                  v-model="toggleParticles"
                />
                <label for="particles_button_1">v2</label>
                <input
                  type="radio"
                  name="particles"
                  value="2"
                  id="particles_button_2"
                  v-model="toggleParticles"
                />
                <label for="particles_button_2">ON</label>
                <input
                  type="radio"
                  name="particles"
                  value="1"
                  id="particles_button_3"
                  v-model="toggleParticles"
                />
                <label for="particles_button_3">OFF</label>
              </div> -->
              <div class="options--label">
                <p>particles</p>
              </div>
            </div>
          </fieldset>
          <Corners />
        </div>
        <!-- </transition-group> -->
      </div>
    </article>
  </transition>
</template>
<script>
import { mapState } from "vuex";
import Corners from "../components/base/Corners.vue";
import axios from "axios";

export default {
  data() {
    return {
      preferenceOptions: ["Dark Mode"],
      locationData: {},
      sunsetData: {},
    };
  },
  components: {
    Corners,
  },
  computed: {
    ...mapState(["modalOpen", "currentModal", "preferences"]),
    darkMode() {
      return this.$store.state.preferences.darkMode;
    },
    toggleFont: {
      get() {
        return this.$store.state.preferences.fontSize;
      },
      set(value) {
        this.$store.commit("changeFontSize", value);
      },
    },
    toggleParticles: {
      get() {
        return this.$store.state.preferences.particles;
      },
      set(value) {
        this.$store.commit("changeParticles", value);
      },
    },
    toggleDarkMode: {
      get() {
        return this.$store.state.preferences.darkMode;
      },
      set(value) {
        this.$store.commit("toggleDarkMode", value);
      },
    },
    toggleSound: {
      get() {
        return this.$store.state.preferences.sound;
      },
      set() {
        this.$store.commit("toggleSound");
      },
    },
  },
  created() {
    axios
      .get("https://geolocation-db.com/json/", { headers: null })
      .then(response => {
        this.locationData = response.data;
        return axios.get(
          `https://api.sunrise-sunset.org/json?lat=${this.locationData.latitude}&lng=${this.locationData.longitude}&formatted=0`
        );
      })
      .then(response => {
        this.sunsetData = response.data;
      })
      .catch(err => console.error(err));
  },
  methods: {
    toggleModal(e, modalName) {
      if (this.$store.state.modalOpen == false) {
        this.$store.commit("openModal", modalName);
        return;
      }
      if (this.$store.state.modalOpen == true) {
        this.$store.commit("closeModal");
        return;
      }
    },
    getTimeString(input) {
      return new Date(input).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    getCoordinates(deg, lat) {
      let absolute = Math.abs(deg);

      let degrees = Math.floor(absolute);
      let minutesNotTruncated = (absolute - degrees) * 60;
      let minutes = Math.floor(minutesNotTruncated);
      let seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

      let direction;

      if (lat) {
        direction = deg >= 0 ? "N" : "S";
      } else {
        direction = deg >= 0 ? "E" : "W";
      }

      return degrees + "° " + minutes + "' " + seconds + '" ' + direction;
    },

    // getLocation() {
    //   navigator.geolocation.getCurrentPosition(
    //     position => {
    //       console.log(position.coords.latitude);
    //       console.log(position.coords.longitude);
    //     },
    //     error => {
    //       console.log(error.message);
    //     }
    //   );
    // },
  },
  watch: {
    "$store.state.preferences.darkMode"(newValue, oldValue) {
      newValue !== oldValue ? document.body.classList.toggle("dark") : "";
    },
  },
};
</script>
<style lang="scss">
:root {
  --step: 0.4s;
}

.v-enter-active,
.v-leave-active {
  transition: opacity;
  transition-duration: calc(var(--step) * var(--total));
  // transition-duration: 3s;
}

.v-leave-active {
  .panel--section {
    transition: var(--step) opacity ease-out, var(--step) filter ease-out;
    transition-delay: calc(var(--step) * (var(--total) - var(--i)));
  }
}

.v-enter-active {
  .panel--section {
    transition: var(--step) opacity ease-in, var(--step) filter ease-in;
    transition-delay: calc(var(--step) * var(--i));
  }
}

.list {
  &-leave-active {
    transition: opacity 0.4s linear,
      transform 0.4s cubic-bezier(0.5, 0, 0.7, 0.4); //cubic-bezier(.7,0,.7,1);
    transition-delay: calc(0.2s * (var(--total) - var(--i)));
  }

  &-enter-active {
    transition: opacity 0.5s linear,
      transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
    transition-delay: calc(0.2s * var(--i));
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-leave-from,
  &-enter-to {
    opacity: 1;
  }

  &-enter-from {
    transform: translateX(-1em);
  }

  &-leave-to {
    transform: translateX(1em);
  }
}

.preferences-header {
  position: absolute;
  top: calc(var(--flow-space) * 2);
}

.preferences {
  padding: calc(var(--flow-space) * 1.5);
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 15rem;
}

.preferences-panel {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 4fr 4fr 5fr;
  grid-auto-rows: 12rem;
  grid-gap: 2.5rem;
}

.location {
  padding: var(--flow-space);
  height: 100%;
  widows: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;

  .img-backdrop {
    position: absolute;
    width: 60%;
    opacity: 0.2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: 0.4s opacity ease, 0.4s transform ease;

    &:hover {
      opacity: 0.4;
      transform: rotate(30deg), translate(-50%, -50%);
    }
  }
}

.panel--section {
  color: white;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: var(--base-font-unit);

  fieldset {
    height: 100%;
  }

  .options {
    height: 100%;
    overflow: hidden;

    display: flex;

    .options-toggle {
      z-index: 10;
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column;

      overflow: hidden;

      transition: 0.35s width ease-out;

      width: 1.5rem;

      input {
        width: 0;
        height: 0;
        position: absolute;
        left: -9999px;
      }

      &:hover {
        label {
          color: rgba(255, 255, 255, 1);
        }
      }

      input+label {
        height: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;
        font-size: var(--base-font-unit);
        padding: 0.5rem;

        transition: 0.35s color ease-in-out;

        color: rgba(255, 255, 255, 0);

        border: 1px solid white;

        &:hover {
          background-color: var(--hover-white);
        }

        &:not(:first-of-type):not(:last-of-type) {
          border-bottom: none;
          border-top: none;
        }
      }

      input:checked+label {
        background-color: var(--hover-white);
      }
    }

    // Fix the margin animation
    .options--label {
      transition: 0.2s opacity ease-in-out, transform 0.5s ease-in-out;
      position: relative;

      p {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        white-space: nowrap;
      }
    }
  }

  &:hover {
    .options-toggle {
      width: 100%;
    }

    .options--label {
      width: 0;
      transform: translateX(1.5rem);
    }
  }

  &:nth-of-type(3) {
    grid-row-end: span 2;
  }

  transition: 0.1s background-color ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background-color: rgba(255, 255, 255, 1);
  }

  h4 {
    padding: calc(var(--flow-space) * 0.3) calc(var(--flow-space) * 0.5);
  }
}
</style>
