<template>
  <fieldset>
    <div class="toggle">
      <input
        type="radio"
        name="id"
        :value="disableText"
        :id="id + '_button_1'"
        v-model="radio"
      />
      <label :for="id + '_button_1'">
        <h5>{{ disableText }}</h5>
      </label>
      <input
        type="radio"
        :name="id"
        :value="enableText"
        :id="id + '_button_2'"
        v-model="radio"
      />
      <label :for="id + '_button_2'">
        <h5>{{ enableText }}</h5>
      </label>
    </div>
  </fieldset>

  <!-- <label
    :for="id + '_button'"
    :class="{ active: isActive }"
    class="toggle__button"
  >
    <span class="toggle__label">{{ labelDisableText }}</span>

    <input
      type="checkbox"
      :disabled="disabled"
      :id="id + '_button'"
      v-model="checkedValue"
    />
    <span class="toggle__switch"></span>
    <span class="toggle__label">{{ labelEnableText }}</span>
  </label> -->
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    enableText: {
      type: String,
      default: "On",
    },
    disableText: {
      type: String,
      default: "Off",
    },
    id: {
      type: String,
      default: "primary",
    },
    defaultState: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change"],
  data() {
    return {
      // currentState: this.defaultState,
      currentRadio: "images",
    };
  },
  // watch: {
  //   defaultState: function defaultState() {
  //     this.currentState = Boolean(this.defaultState);
  //   },
  // },
  computed: {
    // checkedValue: {
    //   get() {
    //     return this.currentState;
    //   },
    //   set(newValue) {
    //     this.currentState = newValue;
    //     // this.$emit("change", newValue);
    //   },
    // },
    radio: {
      get() {
        return this.currentRadio;
      },
      set(newValue) {
        this.currentRadio = newValue;
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style scoped lang="scss">
/* TOGGLE STYLING */
.toggle {
  margin-top: 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input+label {
    width: 8rem;
    margin: 0;
    padding: 0.25rem 0.5rem;
    font-size: var(--base-font-unit);
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    line-height: 140%;
    color: white;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0);

    transition: border-color 0.15s ease-out,
      background-color 0.15s ease-out 0.1s;

    &:first-of-type {
      // border-radius: 6px 0 0 6px;
      // border-right: none;
    }

    &:last-of-type {
      // border-radius: 0 6px 6px 0;
      // border-left: none;
    }
  }

  input:hover+label {
    border: 1px solid rgba(255, 255, 255, 1);
    // background-color: rgba(255, 255, 255, 0.2);
  }

  input:checked+label {
    background-color: var(--hover-white);
    z-index: 1;
  }

  input:focus+label {}
}
</style>
